import React from "react"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { GettingStartedPageType } from "../types/GettingStartedPageType"
import type { AllLayoutsType, UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  Layout,
  PublicRoute,
  ROUTES,
  isBrowser,
  LoadingIndicator,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

const GettingStarted = React.lazy(() => import("../components/GettingStarted"))

type Props = {|
  pageContext: {
    lang: string,
  },
  location: Object,
  data: {
    prismicGettingStartedPage: GettingStartedPageType,
    prismicLayout: AllLayoutsType,
  },
|}

const GettingStartedPage = (props: Props) => {
  const { pageContext, location, data } = props
  const scriptsGettingStarted = [DATA_LAYER_SCRIPT]

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang
  const { prismicLayout = {}, prismicGettingStartedPage = {} } = data
  const pageContent = prismicGettingStartedPage.data || {}
  const layout = prismicLayout.data || {}

  const renderGettingStartedPage = ({ user, checkingSession }: UserType) => (
    <Layout data={layout} lang={lang} user={user} isLoading={checkingSession}>
      {renderSEO(
        pageContent,
        ROUTES(lang).GETTING_STARTED,
        lang,
        scriptsGettingStarted
      )}
      {isBrowser && (
        <React.Suspense fallback={<LoadingIndicator />}>
          <GettingStarted
            data={pageContent}
            lang={lang}
            user={user}
            location={location}
            layoutData={layout}
          />
        </React.Suspense>
      )}
    </Layout>
  )

  return (
    <PublicRoute component={renderGettingStartedPage} {...props} lang={lang} />
  )
}

export const query = graphql`
  query($lang: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        lmod_gu_err {
          text
        }
        sign_out_button {
          text
        }
        sign_in_button {
          text
        }
        logo {
          alt
          url
        }
        ...LayoutUnits
        body {
          ...LayoutBodyNavigation
        }
        body1 {
          ...LayoutBody1Navigation
        }
      }
    }
    prismicGettingStartedPage(lang: { eq: $lang }) {
      data {
        available_on_label {
          raw
          text
        }
        body {
          ... on PrismicGettingStartedPageBodySections {
            items {
              id
              image {
                alt
                url
              }
              name {
                raw
                text
              }
              tab {
                raw
                text
              }
            }
            primary {
              section_subtitle {
                raw
                text
              }
              section_title {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicGettingStartedPageBody1StartTrainingPopup {
            id
            primary {
              training_continue_link {
                raw
                text
              }
              training_decrease_desc {
                raw
                text
              }
              training_decrease_percents {
                raw
                text
              }
              training_description {
                raw
                text
              }
              training_image {
                alt
                url
              }
              training_improve_decs {
                raw
                text
              }
              training_improve_label {
                raw
                text
              }
              training_popup_subtitle {
                raw
                text
              }
              training_popup_title {
                raw
                text
              }
              training_view_link {
                raw
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicGettingStartedPageBody2DeviceSetupPopup {
            id
            items {
              device_popup_step {
                raw
                text
              }
              device_popup_step_1 {
                raw
                text
              }
              device_popup_step_desc {
                raw
                text
              }
              device_popup_step_desc_1 {
                raw
                text
              }
            }
            primary {
              device_popup_desc {
                raw
                text
              }
              device_popup_got_app_desc {
                raw
                text
              }
              device_popup_got_app_subtile {
                raw
                text
              }
              device_popup_id
              device_popup_login_desc {
                raw
                text
              }
              device_popup_login_subtitle {
                raw
                text
              }
              device_popup_media {
                url
                link_type
              }
              device_popup_steps_desc {
                raw
                text
              }
              device_popup_steps_desc_1 {
                raw
                text
              }
              device_popup_title {
                raw
                text
              }
            }
          }
        }
        body3 {
          ... on PrismicGettingStartedPageBody3EquipmentPopup {
            items {
              id
              equipment_popup_img {
                alt
                url
              }
            }
            primary {
              equipment_popup_desc {
                raw
                text
              }
              equipment_popup_extra_desc {
                raw
                text
              }
              equipment_popup_id
              equipment_popup_includes_desc {
                raw
                text
              }
              equipment_popup_includes_title {
                raw
                text
              }
              equipment_popup_programs_desc {
                raw
                text
              }
              equipment_popup_programs_title {
                raw
                text
              }
              equipment_popup_title {
                raw
                text
              }
            }
          }
        }
        body4 {
          ... on PrismicGettingStartedPageBody4Programpopup {
            slice_type
            items {
              level {
                raw
                text
              }
              program {
                raw
                text
              }
            }
            primary {
              benefits {
                raw
                text
              }
              categories {
                raw
                text
              }
              description {
                raw
                text
              }
              get_started_button {
                raw
                text
              }
              intensity_description {
                raw
                text
              }
              intensity_icon {
                alt
                url
              }
              program_id
              program_popup_title {
                raw
                text
              }
              video {
                raw
                text
              }
              view_program_button {
                raw
                text
              }
            }
          }
          ... on PrismicGettingStartedPageBody4Equipments {
            slice_type
            items {
              equipment_icon {
                alt
                url
              }
              equipment_name {
                raw
                text
              }
            }
            primary {
              program_id
            }
          }
          ... on PrismicGettingStartedPageBody4Faqs {
            slice_type
            items {
              answers {
                raw
                text
              }
              question {
                raw
                text
              }
            }
            primary {
              program_id
            }
          }
        }
        devices_image {
          alt
          url
        }
        hero_image {
          alt
          url
        }
        hero_title {
          raw
          text
        }
        updated_subscription_notification {
          raw
          text
        }
        hero_video {
          raw
        }
        need_equipment {
          raw
          text
        }
        none {
          raw
          text
        }
        page_author {
          raw
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        plans_image {
          alt
          url
        }
        program_benefits_title {
          text
          raw
        }
        program_category_title {
          raw
          text
        }
        program_description_title {
          raw
          text
        }
        program_equipment_title {
          raw
          text
        }
        program_intensity_title {
          raw
          text
        }
        ready_training_button {
          raw
          text
        }
        scroll_down_label {
          raw
          text
        }
      }
    }
  }
`

export default withPreview(
  React.memo(GettingStartedPage, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
  })
)
